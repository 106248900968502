@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.shopini-logistics {
    font-weight: 500;
    font-size: 12px;
    color: #c3cbd4;
    letter-spacing: 0.21em;
}

.title {
    max-width: 524px;
    font-size: 22px;
    font-weight: 600;
    color: #0000ff;
    @include media-breakpoint-up(md) {
        max-width: unset;
        margin-bottom: 5px;
        font-size: 28px;
    }
}

.sub-title {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 500;
    max-width: 436px;
    @include media-breakpoint-up(md) {
        margin-bottom: 27px;
        font-size: 22px;
    }
}

.btn-overrides {
    font-weight: 600 !important;
    font-size: 18px !important;
    padding: 7px 31px !important;
    height: auto !important;
    line-height: var(--line-height-button-27) !important;
    min-width: 166px !important;
}
