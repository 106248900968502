@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.shopini-logistics {
    font-weight: 500;
    font-size: 12px;
    color: #c3cbd4;
    letter-spacing: 0.21em;
    @include media-breakpoint-up(md) {
        font-size: 12px;
    }
}

.title {
    max-width: 200px;
    margin-bottom: 19px;
    font-size: 22px;
    font-weight: 600;
    color: #0000ff;
    @include media-breakpoint-up(md) {
        max-width: 289px;
        margin-bottom: 18px;
        font-size: 28px;
    }
}

.sub-title {
    margin-bottom: 19px;
    font-size: 22px;
    font-weight: 500;
    @include media-breakpoint-up(md) {
        max-width: 355px;
        margin-bottom: 20px;
        font-size: 22px;
    }
}

.app-store {
    margin-right: 21px;
}

.btn-overrides {
    font-weight: 500 !important;
    font-size: 14px !important;
    padding: 7px 21px !important;
    height: auto !important;
    line-height: var(--line-height-button-27) !important;
    @include media-breakpoint-up(sm) {
        padding: 5px 25px;
    }
}
