@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.btn-overrides {
    font-weight: 500 !important;
    font-size: 14px !important;
    padding: 7px 26px !important;
    height: auto !important;
    line-height: var(--line-height-button-27) !important;
    min-width: unset !important;
    @include media-breakpoint-up(sm) {
        padding: 5px 25px;
    }
}

.shopini-icon {
    width: 80px;
    height: 27px;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../images/shopini-logo-mob.svg");
    @include media-breakpoint-up(sm) {
        width: 134px;
        height: 45px;
        background-image: url("../../images/shopini-logo.svg");
    }
}
