@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.btn-overrides {
    font-weight: 600 !important;
    font-size: 18px !important;
    padding: 7px 31px !important;
    height: auto !important;
    line-height: var(--line-height-button-27) !important;
    min-width: 166px !important;
    border: 1px #0000ff solid !important;
    color: #0000ff;
    &:hover {
        background-color: #0000ff;
        border: 1px transparent solid !important;
    }
}

.content {
    font-size: 22px;
    font-weight: 500;
}


.outline-btn-overrides {
    font-weight: 600 !important;
    font-size: 18px !important;
    padding: 7px 25px !important;
    height: auto !important;
    line-height: var(--line-height-button-27) !important;
    min-width: 166px !important;
    border: 1px #0000ff solid !important;
    color: #0000ff;
    &:hover {
        background-color: #0000ff;
        border: 1px transparent solid !important;
    }
    &:active {
        background-color: #0000ff !important;
    }
}