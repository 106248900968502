@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.seamless-technology {
    font-size: 24px;
    font-weight: 600;
    color: #0000ff;
    margin-bottom: 21px;
    @include media-breakpoint-up(xl) {
        margin-bottom: 18px;
        max-width: 645px;
    }
}
