@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.box {
    max-width: 250px;
    &:global(.col-xl) {
        @include media-breakpoint-up(xl) {
            flex: 0 0 13.5% !important;
            max-width: 13.5% !important;
        }
    }
}

.now-ship {
    &:global(.col-xl) {
        @include media-breakpoint-up(xl) {
            flex: 0 0 30% !important;
            max-width: 30% !important;
        }
    }
}

.ship-from-to {
    &:global(.col-xl) {
        @include media-breakpoint-up(xl) {
            flex: 0 0 18% !important;
            max-width: 18% !important;
        }
    }
}

.pay-as-actual-weight {
    &:global(.col-xl) {
        @include media-breakpoint-up(xl) {
            flex: 0 0 37.5% !important;
            max-width: 37.5% !important;
        }
    }
}

.header {
    background-color: rgba(61, 138, 220, 1);
    padding: 22px 33px 5px 33px;
    line-height: 1.1 !important;

    @include media-breakpoint-up(xl) {
        padding: 13px 0;
    }
}
