@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.seamless-background-image-tape {
    @include media-breakpoint-up(lg) {
        left: 0;
        bottom: 0;
        transform: translateY(8%);
        width: 9.583%;
        &::after {
            content: "";
            display: table;
            clear: both;
        }
        &::before {
            content: "";
            width: 1px;
            margin-left: -1px;
            float: left;
            height: 0;
            padding-top: 208.696%;
        }
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url("../../images/seamless-background-image-tape.svg");
    }
}

.contents {
    padding: 72px 18px;
    @include media-breakpoint-up(lg) {
        padding: calc(min(4.02%, 58px)) calc(min(6.111%, 88px));
    }
}

.shopini-logistics {
    font-weight: 500;
    font-size: 12px;
    color: #c3cbd4;
    letter-spacing: 0.21em;
}
