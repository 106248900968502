@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.us-warehouse {
    .us-content-wrapper {
        $us-mob-hight: 688px;
        $us-mob-width: 428px;
        $us-hight: 978px;
        $us-width: 1523px; // I need to figure out why I can not this variable

        max-width: 1523px;
        margin: auto;
        overflow: hidden;
        position: relative;

        &.rtl:before {
            background-image: url("./every-thing-mobile-rtl.svg");
            @include media-breakpoint-up(sm) {
                background-image: url("./every-thing-rtl.svg");
            }
        }

        &:before {
            content: "";
            display: block;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-image: url("./every-thing-mobile.svg");
            padding-top: calc(1272px - 100vw);
            @media (min-width: calc($us-mob-width + 28px)) {
                @include media-breakpoint-down(sm) {
                    padding-top: 155.9%;
                }
            }

            @include media-breakpoint-up(sm) {
                background-image: url("./every-thing.svg");

                @media (max-width: 1523px) {
                    padding-top: calc(calc(1523px + 64.5vw) - 100vw);
                    background-size: cover;
                }

                @media (min-width: 1523px) {
                    background-size: contain;
                    padding-top: 64%;
                }
            }
        }

        > .content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            margin-top: 20px;

            @media (min-width: calc($us-mob-width + 28px)) {
                margin-top: 40px;
            }

            @include media-breakpoint-up(sm) {
                @media (max-width: 1523px) {
                    margin-top: calc(max(140px, min(10vw)));
                }
                @media (min-width: 1523px) {
                    margin-top: 140px;
                }
            }

            .custom-fs-3 {
                font-size: 20px;
                @include media-breakpoint-up(sm) {
                    font-size: 24px;
                }
            }
            .custom-fs-2 {
                font-size: 32px;
                @include media-breakpoint-up(sm) {
                    font-size: 48px;
                }
            }

            .steps-width {
                @include media-breakpoint-up(sm) {
                    transform: translateX(calc(max(-10%, -50px)));
                    max-width: calc((1523px - 100vw) * 1.3 + 750px);

                    @media (min-width: 1523px) {
                        transform: translateX(-50px);
                        max-width: 750px;
                    }
                }
            }
        }
    }
}
