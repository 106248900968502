form#upload-incoming-orders {
    div.dzu-dropzone {
        background: rgba(0, 0, 255, 0.03);
        border: 1.4px dashed #0000ff;
        border-radius: 10px;
        min-height: 260px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        label.dzu-inputLabel {
            div.upload-files {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                span.light-text {
                    color: #243548;
                    font-weight: 400;
                    opacity: 0.57;
                }
    
                span.lighter-text {
                    opacity: 0.3;
                }
            }
        }
        input.dzu-input {
            display: none !important;
        }
    }
}
