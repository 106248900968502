@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.why-shopini {
    margin-bottom: 13px; // +33 from card container
    @include media-breakpoint-up(lg) {
        margin-bottom: 16px; // +33 from card container
    }
}

.list {
    margin: 0 auto;
    max-width: 83.334%;
    @include media-breakpoint-up(lg) {
        max-width: calc(min(94.306%, 1358px));
    }
}

.title {
    margin-bottom: 26px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 29px;
    }
}
