@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.solutions {
    margin: 0 auto;
    max-width: calc(max(97.778%, 352px));
    padding: calc(min(7.22%, 26px)) calc(min(7.778%, 28px)) 20px
        calc(min(6.111%, 22px));
    @include media-breakpoint-up(sm) {
        max-width: calc(min(83.473%, 1202px));
        padding: calc(min(7.36%, 106px)) 0 calc(min(7.778%, 112px)) 0;
    }
}

.tab-bar {
    display: flex;
    justify-content: center;
    background-color: #e4e8f1;
    border-radius: 60px;
    font-weight: 500;
    font-size: 13px;
    padding: 4px 5px;
    margin-bottom: 23px;
    @include media-breakpoint-up(sm) {
        font-size: 21px;
        padding: 7px 9px;
        margin-bottom: 32px;
    }

    > a {
        flex-grow: 1;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: bottom; // fix extra space cause by hidden overflow
        border-radius: 72px;
        transition: color 300ms, background-color 300ms;
        padding: 9px 23px;
        color: #000000;
        @include media-breakpoint-up(sm) {
            padding: 15px 34px;
        }
        &:hover {
            color: #0000ff !important;
        }
        &.active {
            background-color: #0000ff !important;
            text-align: center;
            color: white !important;
        }
    }
}

.tab-content-active {
    background-color: unset;
}
