@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

// ratio mob 189.25
// ratio 42.22

.rate-calculator {
    .header-wrapper {
        // preserve aspect ratio
        position: relative;
        padding-bottom: 10px;

        @include media-breakpoint-up(sm) {
            padding-bottom: 44px;
        }
        &:before {
            content: "";
            display: block;
            background-image: url("./calculator-en-mobile.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            padding-top: 189.25%; // aspect ratio

            @include media-breakpoint-up(sm) {
                background-size: cover;
                background-position: center;
                background-image: url("./calculator-en.svg");
                padding-top: 42.22%; // aspect ratio
                min-height: 475px;
            }
        }

        &.right-to-left {
            &:before {
                background-image: url("./calculator-ar-mobile.svg");
                @include media-breakpoint-up(sm) {
                    background-image: url("./calculator-ar.svg");
                }
            }
        }

        > .content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin-top: 58px;

            color: black;

            &.rtl {
                text-align: right !important;
            }

            @include media-breakpoint-up(sm) {
                margin-top: 53px;
                padding-left: calc(min(9.8vw, 74px)) !important;

                &.rtl {
                    padding-right: calc(min(9.8vw, 74px)) !important;
                }
            }

            .header-title {
                font-size: 35px;
                @include media-breakpoint-up(sm) {
                    font-size: 62px;
                }
            }

            .header-subtitle {
                font-size: 25px;
                max-width: 250px;
                @media (max-width: 400px) {
                    font-size: 25px;
                }

                @include media-breakpoint-up(sm) {
                    max-width: 450px;
                    font-size: 42px;
                    margin-bottom: calc(min(10vw, 50px));
                }

                &.ar {
                    @include media-breakpoint-up(sm) {
                        font-size: 42px;
                        max-width: 900px;
                    }
                }
            }

            .rate-calculator {
                @include media-breakpoint-up(sm) {
                    font-weight: bold;
                }
            }
        }
    }
    .form-padding {
        padding-bottom: 46px;
        @include media-breakpoint-up(sm) {
            padding-bottom: 62px;
        }
    }

    .shipment-company-card {
        padding: 16px 25px;
        .sc-logo {
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 64px;
            height: 64px;
        }
    }

    .results-container {
        margin: 0 12px;

        @include media-breakpoint-up(sm) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .custom-fs-1 {
        font-size: 21px;
        @include media-breakpoint-up(sm) {
            font-size: 28px;
        }
    }

    .custom-fs-2 {
        font-size: 14px;
        @include media-breakpoint-up(sm) {
            font-size: 22px;
        }
    }

    .custom-fs-3 {
        font-size: 12px;
        @include media-breakpoint-up(sm) {
            font-size: 18px;
        }
    }

    .calculator-foot-fs3 {
        font-size: 18px;
    }

    .contents-mw {
        max-width: 875px;
    }

    .results-padding-top {
        padding-top: 42px;
        @include media-breakpoint-up(sm) {
            padding-top: 62px;
        }
    }

    .btn-blue {
        background-color: #1922fb;
    }

    .calculator-faq-container {
        padding: 15px 0;
        background-color: #00eb8c;

        .faq-link {
            max-width: 150px;
            margin: auto;
            cursor: pointer;
        }

        .text-blue {
            color: #0000ff;
        }
    }
}
