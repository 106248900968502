@import "../../constants/constants.css";

.shipping-options-container-wrapper {
    background: #eef1f5;
    padding-top: 25px;
    padding-bottom: 70px;

    input[type=checkbox], input[type=radio] {
        height: auto!important;
    }

    .promotion-card {
        border: none;
        align-items: start;
        .card-body {
            width: 100%;
        }
    }

    .services-blk {
        padding: 8px 20px;
    }
}

.shipping-services-modal {
    .shipping-companies-services {
        background-clip: padding-box;
        border: 1px solid #ced4da;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        cursor: pointer;

        input {
            vertical-align: middle;
        }
    }
}