@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.address-container {
    max-width: 1250px;
    .fs-2-custom {
        font-size: 32px;

        @include media-breakpoint-up(sm) {
            font-size: 48px;
            font-weight: 500;
        }
    }

    .fs-3-custom {
        font-size: 20px;

        @include media-breakpoint-up(sm) {
            font-size: 24px;
        }
    }

    .always-ensure {
        max-width: 390px;

        @include media-breakpoint-up(sm) {
            max-width: 567px;
        }
    }
}
