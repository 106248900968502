@import "../../../constants/constants.css";
.favorite-brands-container {
    background-color: var(--grey-background-section-color) !important;
    align-items: center;
    padding: 40px 0px 20px 0px;
}

.favorite-brands-container .carousel .carousel-inner .active {
    background-color: transparent;
}

.imageSet-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 170px;
}

.imageSet-container-banner {
    display: flex;
    flex-direction: row;
    justify-content: end;
	-webkit-justify-content: flex-end;
    min-height: 170px;
}

.banner-img-margin-en {
	margin-top: 2.7rem;
	margin-right: 15rem;
}

.banner-img-margin-ar {
	margin-top: 2.7rem;
	margin-left: 15rem;
}

/* .imageSet-container-banner img.image1, .imageSet-container-banner img.image3 {
	max-width: 75% !important;
	margin-top: -3rem !important;
	height: auto;
} */

/* .imageSet-container-banner img.image3 {
	padding-right: 7rem;
} */

.imageSet-container-banner img.image1, img.image11 {
	max-width: 72% !important;
	height: auto;
}

.imageSet-container-banner img.image3 {
	max-width: 60% !important;
	height: auto;
}

.imageSet-container-banner img.image5 {
	max-width: 70% !important;
	height: auto;
}

.imageSet-container-banner img.image7 {
	max-width: 76% !important;
	height: auto;
}



@media only screen and (min-width: 769px) and (max-width: 1799px) {
	.banner-img-margin-en {
		margin-top: 2.8rem;
		margin-right: 7rem;
	}
	
	.banner-img-margin-ar {
		margin-top: 2.8rem;
		margin-left: 7rem;
	}

	/* .imageSet-container-banner img.image3 {
		max-width: 80% !important;
	}	 */

	/* .imageSet-container-banner img.image1 {
		max-width: 80% !important;
	} */

	.imageSet-container-banner img.image1 {
		max-width: 82% !important;
	}
	
	.imageSet-container-banner img.image3 {
		max-width: 72% !important;
	}
	
	.imageSet-container-banner img.image5 {
		max-width: 77% !important;
	}
	
	.imageSet-container-banner img.image7 {
		max-width: 85% !important;
	}

	.imageSet-container-banner img.image11 {
		max-width: 73% !important;
	}
}

/* @media only screen and (min-width: 495px) and (max-width: 768px) {
	.imageSet-container-banner img.image0, .imageSet-container-banner img.image2 {
		margin-top: 14rem;
		max-width: 60% !important;
	}	

} */

@media only screen and (max-width: 768px){
	.imageSet-container-banner {
		justify-content: space-around;
	}

	/* .imageSet-container-banner img.image0, .imageSet-container-banner img.image2 {
		margin-top: 18.5rem;
		margin-left: -6.5rem;
		max-width: 120%;
	} */

	.imageSet-container-banner img.image0, .imageSet-container-banner img.image6 {
		margin-top: 19rem;
		max-width: 100%;
	}

	.imageSet-container-banner img.image4, .imageSet-container-banner img.image10 {
		margin-top: 16.6rem;
	}

	.imageSet-container-banner img.image8 {
		margin-top: 15.5rem;
	}

	.imageSet-container-banner img.image12 {
		margin-top: 18rem;
	}

	.imageSet-container-banner img.image2 {
		margin-top: 16rem;
		max-width: 90%;
	}

	.imageSet-container img {
		max-width: 5rem;
	}

	.imageSet-container {
        flex-wrap: wrap;
    }
}
