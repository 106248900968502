.track-section {
    height: fit-content;
    padding-bottom: 3rem;
    .consol-banner {
        background: url("./banner.svg") no-repeat;
        background-size: cover;
        background-position: bottom;
        height: 38vh;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 3rem 0;
        p.track-subtext {
            font-size: 1.7vw;
            max-width: 15vw;
            padding-top: 6vh;
            margin-left: 7vw;
        }
        h1 {
            font-size: 3.1vw;
            color: white;
            font-weight: 600;
            padding-top: 10vh;
            white-space: nowrap;
        }
        // .tracking-box {
        //     margin-bottom: 3rem !important;
        // }
    }
    .tracking-box {
        display: flex;
        padding: 2rem 0 1rem !important;
        margin-top: 2rem;
        align-items: start;
        justify-content: space-between;
        border-radius: 7.42331px !important;
        width: 589px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
    }
    .calculator-box {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
        border-radius: 7.42331px !important;
    }
    .soon {
        font-size: 1.1rem !important;
        color: #22d180;
    }
    .tracking-list {
        margin: auto;
        border-radius: 7.42331px !important;
        .status-icon {
            height: 8px;
            width: 8px;
            background-color: #bbb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 5px;
            background: #ecc94b;
        }
        .date {
            color: #66727F
        }
    }
    .thick-border {
        border: 2px solid #0000FF !important;
        font-weight: 700;
    }
    .left-tracking-border {
        border-left: 2px solid #E4E8F1 !important;
    }
    .gray-border {
        border: 1.5px solid #E4E8F1 !important;
        color: #fff !important
    }
}

//max-width: 768px
@media only screen and (max-width: 768px) {
    .track-section {
        .consol-banner {
            p.track-subtext {
                font-size: 4vw;
                padding-top: 7rem;
                max-width: 100%;
                text-align: center;
                margin-left: 0;
            }
            h1 {
                font-size: 7.3vw;
                padding-top: 1rem;
            }
            .tracking-box {
                width: 94%;
                flex-direction: column;
                padding-inline: 1rem !important;
                padding-top: 1rem !important;
                form {
                    margin: 0 !important;
                }
                button {
                    margin: 0 !important;
                    width: 100%;
                }
            }
        }
        // .tracking-list {
        //     width: 80vw;
        // }
    }

}