@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.schedule-a-call {
    background-color: #ffffff;
    padding-bottom: 15px;
    @include media-breakpoint-up(lg) {
        padding-bottom: 41px;
    }
}

.contents {
    max-width: calc(min(90.834%, 327px));
    margin: 0 auto;
    margin-top: calc(min(38px, 10.556%));
    @include media-breakpoint-up(lg) {
        max-width: unset;
        margin-top: calc(min(71px, 4.93%));
    }
}

.image {
    min-width: 360px;
    width: 100%;

    @include media-breakpoint-up(lg) {
        max-width: 667px;
        width: 46.32%;
    }
}
