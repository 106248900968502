@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.directory-page {
    .stores-container {
        .card-container {
            > .card-image {
                height: 57px;
                background-position: bottom center;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-color: #ffffff;
                margin-bottom: 21px;
                @include media-breakpoint-up(sm) {
                    height: 75px;
                    margin-bottom: 29px;
                }
            }

            > .card-title {
                font-weight: 500;
                font-size: 14px;
                color: #0000ff;
                @include media-breakpoint-up(sm) {
                    font-size: 18px;
                }
            }
        }
    }
}
