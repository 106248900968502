.promo-code-container {
    span {
        &.anchor-link {
            color: var(--step-text-color);
            font-size: 14px;
            cursor: pointer;

            svg {
                width: 12px;
                margin-left: 15px;
            }
        }
    }
    .form-row {
        padding: 10px 0px;
        div {
            margin: 5px 0px;
        }
        button {
            color: var(--green);
            border: 1px solid var(--green)!important;
            border-radius: 6px;
            background: none;
            width: 100%;
            min-width: 100%;
            &:active {
                background: none!important;
                color: var(--green)!important;
            }
        }
        input {
            width: 100%;
            &::placeholder {
                font-size: 0.85em;
            }
        }          
    }
    .has-error {
        color: var(--step-text-color);
    }
    .promo-banner {
        width: 100%;
        min-height: 40px;
        background: rgba(255, 143, 107, 0.15);
        border-radius: 6px;
        font-size: 16px;
        line-height: 24px;
        color: var(--step-text-color);

        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 0px;
        padding: 10px;

        img {
            padding: 10px;
        }
		.pointer {
			cursor: pointer !important;
		}
    }

	.default-promocode {
		background: rgba(34, 209, 128, 0.06);
		border: 2px dashed #22D180;
		border-radius: 6px;
		color: #22D180 !important;
		button.copy-btn {
			background: #22D180;
			color: #FFFFFF;
			border: none;
			border-radius: 6px;
			white-space: nowrap;
			max-height: 35px;
			padding: 0.3rem 0.5rem;
		}
		div.col-8 {
			max-width: 63% !important;
		}
	}
	
}