@import "../../constants/constants.css";

.widget {
    .tab-content {
        display: flex;
        justify-content: center;
    }
    .pending-parcel-msg-ar {
        margin-right: 2.5rem;
    }
    .pending-parcel-msg-en {
        margin-left: 2.5rem;
    }
    .mobile-count-margin-right {
        margin-right: 2.8rem;
    }
    .mobile-count-margin-left {
        margin-left: 2.8rem;
    }
}
.parcel-accordion-items {
    padding: .5rem 0rem 0.5rem 0rem;
    background-color: #fff;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    border: 1px solid #E4E8F1;
    overflow: hidden;
    div {
        // display: contents;
    }
}

.parcel-table {
    font-size: 13px !important;
    tr {
        &.selected-row {
            background: #FAFAFF;
        }
    }
    .form-check-input {
        margin-top: 0px;
        height: 16px!important;
        width: 16px!important;
    }
    th {
        vertical-align: middle!important;
    }
    td {
        height: auto;
        border-bottom: 1px solid #dee2e6;
    }
    .parcel-accordian {
        td {
            border: none;
        }
        .ship-now-btn {
            margin: 15px 0px;
            display: flex;
            flex-flow: column;
            justify-content: right;
            align-items: flex-end;
            button {
                min-width: 175px;
                width: 220px;
                font-weight: 500;
                font-size: 13px;
                line-height: 24px;
                min-height: 38px;
            }
        }
    }
}

.parcel-search-form {
    border: 0 !important;
    .search-row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -5px;
        margin-left: -5px;
        justify-content: center;
        .search-col {
            padding-right: 5px;
            padding-left: 5px;
            .input-group {
                padding-bottom: 13px;
            }
            .form-control {
                border-radius: 0px;;
            }
            button {
                min-width: 100%!important;
                min-height: 44px!important;
            }
        }
    }
}

.parcel-drawer-details-tab {
    .item-imgs-div {
        margin-top: 10px;
        padding-left: .5rem!important;
        margin-bottom: 1rem;
    }
}

.dashboard-container {
    .widget {
        #mparcels {
            #nav-tabContent {
                .bx-info-circle {
                    font-size: 20px;
                }
                button {
                    min-width: 220px;
                    min-height: 38px;
                }
            }
            .nav {
                .nav-item {
                    margin-top: 8px;
                    .nav-link {
                        border-top-left-radius: 6px;
                        border-top-right-radius: 6px;
                        height: 44px;
                        &:not(.active) {
                            background: #F4F6F9;
                            border: 1px solid #E4E8F1;
                            margin-bottom: 0px;
                        }
                        &.active {
                            background-color: #ffffff;
                        }
                    }
                }
            }
            .parcel-pagination {
                display: inline-flex;
                float: right;
                flex-wrap: wrap;
            }
        }
    }
}

.flex-row-space-between-center-align {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
}

.tbl-hd-bg {
    background: #F4F6F9;
    height: 40px;
}

#mytable > thead > tr > th {
    font-style: normal;
    font-weight: normal;
    /* font-size: 12px;
    line-height: 160%; */
    color: #8798A9;
}

#mytable .txtclr {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #243548;
}

.td-sm-txtclr {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    color: #8798A9;
}

.padtb-15 {
    padding: 15px 0px 0px 0px;
}

.badge-bg {
    background: #0000ff;
    color: #fff;
    font-size: 10px;
    border: 1px solid #0000ff;
    line-height: 1rem;
}

.badge-bg-grn {
    background: #00eb8c;
    color: #fff;
    font-size: 10px;
    border: 1px solid #00eb8c;
    line-height: 1rem;
}

.badge-bg-orng {
    background: #FF7F5C;
    color: #fff;
    font-size: 10px;
    border: 1px solid #FF7F5C;
    line-height: 1rem;
}

.padding-nav-27 {
    padding-left: 27px;
}

.padding-nav-59 {
    padding-left: 59px;
}

.pdl13 {
    padding-left: 13px;
}


.circle-gr {
    border-radius: 50%;
    padding: 5px;
    background: #00eb8c;
    border: 1px solid #00eb8c;
    color: #fff;
    text-align: center;
    font-size: 9px;
}

.parcel-address-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #243548;
    line-height: 26px;
    position: relative;
}

.parcel-address-title:after {
    content: "";
    width: auto;
    height: 1px;
    background: #243548;
    display: block;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
}

.blmargin25 {
    margin-left: 25px;
}

.flgus-ml {
    margin-left: 15px;
}

.adrs-lcol {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    color: #8798A9;
    width: 37% !important;
}

.adrs-rcol {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: #243548;
}

.adr-p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    color: #243548;
}

.main-section {
    margin-top: 120px;
}

.hiddenRow {
    padding: 0 4px !important;
    background-color: #F4F6F9;
    font-size: 13px;
}

.bgtrw {
    background-color: #fff;
}

.bgtr {
    font-style: normal;
    background-color: #F4F6F9;
    border-bottom-color: #F4F6F9;
}

#activeParcelTable nav > div > a {

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #243548;
}

#activeParcelTable > thead > tr > th {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    color: #8798A9;
}

#activeParcelTable .txtclr {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #243548;
}

#nav-home-tab {
    margin-left: 0px !important;
}

.imgtdcls {
    width: 20%;
    background-color: #F4F6F9;
    border-top: 0px !important;
}

#imgslider .row > .column {
    padding: 0 8px;
}

#imgslider .row:after {
    content: "";
    display: table;
    clear: both;
}

#nav-routep-tab {
    background-color: #ffffff;
}

#apmodal .nav-tabs .nav-item {
    margin-bottom: -1px;
    margin-right: 15px;
}

.mrl0 {
    margin-left: 0px !important;
}

#apmodal .active {
    background-color: #ffffff !important;
}

#apmodal nav > div > a {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #243548;
}

#apmodal .nav-link {
    background: #F4F6F9;
}

.fontn16-rght {
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #243548;
    text-align: right;
}

.fontn16 {
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #243548;
}

.fontn12 {
    font-weight: normal;
    font-size: 12px !important;
    line-height: 160% !important;
    color: #66727F !important;
}

.fontnclr12 {
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    color: #8798A9;
}

.link-items-popup {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0A20E1;
}

.rwtb {
    border-top: 0px;
}

.font-badge {
    font-size: 13px;
    line-height: 160%;
    text-align: center;
}

.custom-clr {
    color: #22D180;
    background-color: #E9FAF2;
}

.transit {
    color: #6F22D1;
    background-color: #E7E9FC;
}

.onhold {
    color: #FF7F5C;
    background-color: #FFF8EB;
}

.dispatched {
    color: #1DB6FD;
    background-color: #E8F8FF;
}

.tr-cursor {
    cursor: pointer;
}

.pimg-gallery > ul {
    margin-bottom: 0;
}

.pimg-gallery > ul > li {
    float: left;
    margin-bottom: 15px;
    margin-right: 20px;
    width: 200px;
}

.pimg-gallery > ul > li a {
    border: 3px solid #FFF;
    border-radius: 3px;
    display: block;
    overflow: hidden;
    position: relative;
    float: left;
}

.pimg-gallery > ul > li a > img {
    -webkit-transition: -webkit-transform 0.15s ease 0s;
    -moz-transition: -moz-transform 0.15s ease 0s;
    -o-transition: -o-transform 0.15s ease 0s;
    transition: transform 0.15s ease 0s;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    height: 100%;
    width: 100%;
}

.pimg-gallery > ul > li a:hover > img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
}

.pimg-gallery > ul > li a:hover .pimg-gallery-poster > img {
    opacity: 1;
}

.pimg-gallery > ul > li a .pimg-gallery-poster {
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: background-color 0.15s ease 0s;
    -o-transition: background-color 0.15s ease 0s;
    transition: background-color 0.15s ease 0s;
}

.pimg-gallery > ul > li a .pimg-gallery-poster > img {
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: opacity 0.3s ease 0s;
    -o-transition: opacity 0.3s ease 0s;
    transition: opacity 0.3s ease 0s;
}

.pimg-gallery > ul > li a:hover .pimg-gallery-poster {
    background-color: rgba(0, 0, 0, 0.5);
}

.pimg-gallery .justified-gallery > a > img {
    -webkit-transition: -webkit-transform 0.15s ease 0s;
    -moz-transition: -moz-transform 0.15s ease 0s;
    -o-transition: -o-transform 0.15s ease 0s;
    transition: transform 0.15s ease 0s;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    height: 100%;
    width: 100%;
}

.pimg-gallery .justified-gallery > a:hover > img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
}

.pimg-gallery .justified-gallery > a:hover .pimg-gallery-poster > img {
    opacity: 1;
}

.pimg-gallery .justified-gallery > a .pimg-gallery-poster {
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: background-color 0.15s ease 0s;
    -o-transition: background-color 0.15s ease 0s;
    transition: background-color 0.15s ease 0s;
}

.pimg-gallery .justified-gallery > a .pimg-gallery-poster > img {
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transition: opacity 0.3s ease 0s;
    -o-transition: opacity 0.3s ease 0s;
    transition: opacity 0.3s ease 0s;
}

.pimg-gallery .justified-gallery > a:hover .pimg-gallery-poster {
    background-color: rgba(0, 0, 0, 0.5);
}

.pimg-gallery .video .pimg-gallery-poster img {
    height: 48px;
    margin-left: -24px;
    margin-top: -24px;
    opacity: 0.8;
    width: 48px;
}

.pimg-gallery.dark > ul > li a {
    border: 3px solid #04070a;
}

.home .pimg-gallery {
    padding-bottom: 80px;
}

.acph {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: #243548;
}

.larrow-gry {
    color: #66727F;
    font-size: 24px;
}

.mrg-lft3 {
    margin-left: -3px;
    cursor: pointer;
}

#itemParcelTable > tbody > tr > td.topbrdr0 {
    border-top: 0px;
}


.font15-gry1 {
    font-weight: normal;
    font-size: 15px;
    line-height: 160%;
    color: #243548;
}

.font16-gry1 {
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #243548;
}

.font16 {
    font-size: 16px;
}

.font16-gry1-rght {
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #243548;
    text-align: right;
}

.font14-gry1 {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    align-items: center;
    color: #243548;
}

.font12-gry3 {
    font-size: 12px;
    line-height: 160%;
    align-items: center;
    color: #8798A9;
}

.font12-rght-gry3 {
    font-size: 12px;
    line-height: 160%;
    align-items: center;
    text-align: right;
    color: #8798A9;
}

#mytable > tr > td.sptopbrdr0 {
    border-top: 0px;
}

#innertble > tr > td.brdr0tbl {
    border-top: 0px;
}

.sptopbrdr0 {
    border-top: 0px !important;
}

.bg-td-gry6 {
    background-color: #F4F6F9;
}

#mytable .table td {
    padding: .5rem 0rem 0.5rem 0rem;
}

.pdngspnlft {
    padding-left: 1rem;
}

.pdngspnrght {
    padding-right: 1rem;
}

.crlgrn8 {
    color: #22D180;
    font-size: 8px;
}

.crlorng8 {
    color: #FA9218;
    font-size: 8px;
}
.crlorange {
    color: #FF7F5C;
    font-weight: 600;
}
.drawer-content {
    background-color: #fff;
}

.drawer {
    width: 450px !important;
}

.font26-gry1 {
    font-weight: 500;
    font-size: 26px;
    line-height: 39px;
    color: #243548;
    text-align: center;
}

.font26 {
    font-weight: 500;
    font-size: 26px;
    color: #243548;
}

.font24-w500 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.font24-gry1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #243548;
}

.font22-gry1 {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: #243548;
}

.font12-gry2 {
    font-size: 12px;
    line-height: 160%;
    color: #66727F;
    text-align: justify;
}

.font24-gry2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 160%;
    color: #66727F;
}

.pdlft20 {
    padding-left: 20px;

}

.pdlft135 {
    padding-left: 135px;
}

.mrgtop-9 {
    margin-top: -9px;
}

.mrgtop4 {
    margin-top: 4px;
}

.btn-rounded-green-smal {
    display: inline-block;
    width: 63px;
    border: none;
    background-color: #22D180;
    color: #F4F6F9;
    align-items: center;
    font-size: 14px;
    line-height: 160%;
    cursor: pointer;
    text-align: center;
    font-weight: normal;
}

.btn-rounded-green-smal:hover {
    background-color: #0000ff;
    color: #F4F6F9;
}

.pt-40 {
    padding-top: 40px;
}

.paddingtop15 {
    padding-top: 1.6rem;
}

.service-blk-ht {
    max-height: 103px;
}

.service-block-pdng {
    padding: .95rem !important;
}

.pdng-lr100 {
    padding: 0px 100px 0px 100px;
}

.pdngtp29 {
    padding-top: 2.95rem;
}

.pdngbp19 {
    padding-bottom: 1.95rem;
}

/*******************End Dashboard *************/


.dashboard-container-wrapper {
    background: #eef1f5;
    padding-top: 25px;
    padding-bottom: 25px;
}

.dashboard-table {
    padding-right: 32px;
}

.widget {
    padding-bottom: 15px;
}

.widget__inner {
    background: #fff;
    padding: 31px 20px 40px;
    position: relative;
}

.aside .widget__inner {
    padding: 26px 53px 17px;
}

.widget__title, .widget__sub-title, .widget__description {
    text-align: center;
}

.widget__content {
    width: 100%;
    max-width: 544px;
    margin: 0 auto;
}

.widget__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    margin: 0 0 30px;
    padding-bottom: 20px;
    position: relative;
}

.widget__title:after {
    content: "";
    width: 280px;
    height: 1px;
    background: #00eb8c;
    display: block;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
}

.widget__title_no-border:after {
    display: none;
}

.widget__sub-title {
    font-size: 24px;
    line-height: 26px;
    color: #243548;
    font-weight: 300;
    margin: 0 0 14px;
}

.widget__description {
    max-width: 490px;
    margin: 0 auto;
    padding-bottom: 12px;
}

.widget__description p {
    margin: 0;
    font-size: 20px;
    line-height: 22px;
    color: #243548;
}

.hp-widgets .widget .form-control {
    /*font-size: 24px;
    line-height: 26px;
    padding-left: 28px;
    padding-top: 20px;
    padding-bottom: 20px;*/
    font-size: 18px;
    line-height: 22px;
    padding: 8px 20px;
}

.aside .widget .form-control {
    padding: 8px 20px;
}

.widget .form-group.aside .widget .form-group {
    margin-bottom: 21px;
}

.widget .btn {
    /* min-width: 268px;*/
}

.aside .widget .btn {
    min-width: 180px;
    padding: 7px 5px 9px;
}

.aside .widget label {
    margin-bottom: 8px;
}

.widget.widget_about .widget__inner {
    padding-bottom: 23px;
    padding-left: 50px;
    padding-right: 50px;
}

.widget.widget_packages .widget__inner {
    padding-top: 18px;
}

.widget.widget_packages .widget__content {
    max-width: 100%;
}

.widget.widget_packages .title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
}

.widget.widget_packages .btn {
    padding: 6px 10px;
    height: auto;
    min-height: 44px;
}

.widget.widget_secondary .widget__inner {
    background: #eceded;
}

.widget.widget_type2 .widget__inner {
    background: #00eb8c;
}

.widget.widget_type2 .widget__title {
    color: #fff;
}

.widget.widget_type2 .widget__title:after {
    background: #fff;
}

.hp-widgets .widget {
    padding-bottom: 30px;
}

// For Light Gallery images
.lg-inner {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

// For fixing not rendering the Gallery from parcel details modal

/* this LightGallery's images outer container, and this
*   container is located inside another container,
*   which has the class lg-backdrop, lg-backdrop has
*   a z-index of 1040, that's why the lg-outer should have
*   a value higher than 1040, and also higher than the value
*   that we'er giving to the parcel details modal, which has
*   the MuiDrawer-modal class.
*/
.lg-outer {
    z-index: 1100 !important;
}

.MuiDrawer-modal {
    z-index: 900 !important;
}

// For fixing showing table's pagination in the navbar while the navbar is opened ( in Dashboard )
.page-item {
    z-index: 0;
}

img.right-illustrator {
	top: 50px;
	min-width: 60% !important;
}

img.left-illustrator {
	top: 50px;
	min-width: 80% !important;
}

img.right-referral-illustrator {
    top: 60px;
    min-width: 60%;
}

img.left-referral-illustrator {
    top: 60px;
    min-width: 60%;
}

button.close-btn-position {
	top: 30px;
	right: 20px; 
}

.font-1 {
	font-size: 1vw !important;
}

i.font-40 {
	font-size: 2.5vw !important;
}

.referral-code {
    background: #FFF;
    border: 2px dashed #FF8F6B;
    border-radius: 6px;
    color: #FF8F6B !important;
    i.copy-btn {
        color: #FFBEA9;
    }
}

.referral-code-copied {
    background: #FFBEA9;
    border: 2px dashed #FFF;
    border-radius: 6px;
    color:  #FFF !important;
    i.copy-btn {
        color: #FF8F6B;
    }
}

.check-icon {
    color: #22D180
}

.notification-slide {
    height: 211px;
    background: #FFF;
    padding: 0 1rem;
}

.carousel-indicators {
    bottom: -20px;
    padding: 0px;
}

input.style-none {
	-webkit-appearance: none !important;
	border: none !important;
	outline: none !important;
	min-width: 1rem !important;
	padding: 0 0;
	text-align: center;
	max-height: 1rem !important;
}

.nowrap-space {
	white-space: nowrap !important;
}

.ReactFlagsSelect-module_secondaryLabel__37t1D {
    position: absolute;
    right: 0;
}

a.btn-add-items {
    border: 1px dashed #0000FF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
    border-radius: 3px;
    font-size: 1.3rem;
}

.MuiFilledInput-underline:before {
    border: none !important
}

label.MuiInputLabel-formControl.MuiInputLabel-animated {
    color: #243548 !important;
    opacity: 0.4 !important;
    text-transform: capitalize;
    font-weight: 400 !important;
}

div.MuiFilledInput-underline.MuiInputBase-formControl {
    background: white;
    border: 1px solid #E4E8F1;
    border-radius: 5px;
    input.MuiFilledInput-input {
        padding-top: 0.9rem;
        padding-bottom: 0;
    }
}

.close-ar {
    button.close {
        margin-left: 0;
        margin-right: auto;
    }

}

@media (max-width: 1200px) {
    .aside .widget .widget__inner {
        padding: 40px 40px;
    }

}

@media (max-width: 1024px) {
    .widget__title {
        font-size: 20px;
        line-height: 22px;
    }

    .aside .widget .btn {
        font-size: 18px;
        line-height: 24px;
    }
	
}

@media (max-width: 992px) {
    .widget__sub-title {
        font-size: 20px;
        line-height: 22px;
    }

    .widget__description p {
        font-size: 14px;
        line-height: 16px;
    }

    .hp-widgets .widget .form-control {
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 16px;
    }

    .widget .form-group .btn {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .widget.widget_about .widget__inner {
        padding-bottom: 23px;
        padding-left: 10px;
        padding-right: 10px;
    }

	.font-1 {
		font-size: 1.4vw !important;
	}
}

// tablet

@media only screen and (min-device-width: 766px) and (max-device-width: 1023px) {
    .dashboard-container {
        width: 100%;
        padding: 0px;
    }

    .dashboard-row .col-md-9.col-sm-12  {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .dashboard-row .row-bg-white {
        background-color: #fff;
    }

	.font-1 {
		font-size: 1.6vw !important;
	}
}

// mobile

@media (min-width: 320px) and (max-width: 765px) {

    .dashboard-container {
        width: 100%;
        padding: 0px;
        .widget {
            .widget__inner {
                background: #fff;
                padding: 0px 0px 0px 0px;
                position: relative;
                width: 100%;
            }
        }
    }

    .imgtdcls {
        width: 100%;
    }

	.font-1 {
		font-size: 1.7vw !important;
	}
}

.dashboard-row {
    .color-inverse-default-button {
        &:hover {
            border: 1px solid !important;
            border-color: var(--button-text-blue) !important;
        }
    }
}

.add-edit-items-hover{
    &:hover{
        background-color: #0000FF !important;
        color:white !important;
        opacity: 1 !important;

        img {
            filter: brightness(0) invert(1) !important;
        }
    }
}

.min-w-0 {
    min-width: 0 !important;
}