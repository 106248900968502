@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

/*
mob:
background 360px 499px , 138.61%
padding 24px 21px , 6.67% 5.83%

desktop:
background: 1439px 491px 34.121%
padding: 89px 77px , 6.19% 5.35%

*/

.directory-page {
    // default, mobile normal
    .header-wrapper {
        > .aspect-ratio-box::after {
            content: "";
            display: table;
            clear: both;
        }

        /* background aspect ratio */
        > .aspect-ratio-box::before {
            content: "";
            width: 1px;
            margin-left: -1px;
            float: left;
            height: 0;
            padding-top: 138.61%; //**
        }

        > .aspect-ratio-box {
            /* background configurations */
            background-repeat: no-repeat; //
            background-position: center; //
            background-size: 100% 100%; //
            background-image: url("./bg-mob.svg"); //

            > .aspect-ratio-box-container {
                /* contents padding */
                padding: 24px 21px; //*

                > .contents {
                    margin: 0 auto;
                    text-align: center;
                    /* contents max width */
                    max-width: 319px; //

                    /* contents custom styles */
                    .shopping-guide {
                        font-size: 31px;
                        font-weight: 700;
                        margin-bottom: 8.8vw;
                    }

                    .signup-btn {
                        width: 206px;
                        height: 55px;
                        font-weight: 500;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        line-height: normal;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        // mobile small
        @media (max-width: 360px) {
            .header-wrapper {
                > .aspect-ratio-box::before {
                    padding-top: calc((2 * 360px - 100vw) * 1.3861);
                }

                > .aspect-ratio-box {
                    background-size: auto 100%;

                    > .aspect-ratio-box-container {
                        padding: 6.67% 5.83%;
                        > .contents {
                            /* contents custom styles */
                            .shopping-guide {
                                margin-bottom: 32px;
                            }
                        }
                    }
                }
            }
        }
        // mobile normal same as default
    }

    @include media-breakpoint-up(sm) {
        // desktop small
        .header-wrapper {
            margin-bottom: 25px;

            > .aspect-ratio-box::before {
                padding-top: calc((2 * 1439px - 100vw) * 0.34121);
            }

            > .aspect-ratio-box {
                background-size: auto 100%;
                background-image: url("./bg.svg");
                object-fit: cover;

                > .aspect-ratio-box-container {
                    padding: 6.19% 5.35%;

                    > .contents {
                        margin: 0;
                        text-align: left;
                        max-width: 456px;

                        /* contents custom styles */
                        .shopping-guide {
                            font-size: 51px;
                            font-weight: 600;
                            margin-bottom: 3.2vw;
                        }
                    }
                }
            }
        }

        &.right-to-left {
            .header-wrapper {
                > .aspect-ratio-box {
                    @include media-breakpoint-up(sm) {
                        background-image: url("./bg-rtl.svg");
                    }
                    > .aspect-ratio-box-container {
                        > .contents {
                            text-align: right;
                            /* contents custom styles */
                            // bla bla
                        }
                    }
                }
            }
        }

        // desktop normal and above
        @media (min-width: 1439px) {
            .header-wrapper {
                > .aspect-ratio-box::before {
                    padding-top: 34.121%;
                }
                > .aspect-ratio-box {
                    background-size: auto 100%;

                    > .aspect-ratio-box-container {
                        padding: 89px 77px;

                        > .contents {
                            /* contents custom styles */
                            .shopping-guide {
                                font-size: 51px;
                                font-weight: 600;
                                margin-bottom: 46px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// just duplicate default
