@import "../../constants/constants.css";
.parcel-table {
    tr {
        &.selected-row {
            background: #fafaff;
        }
    }
    th {
        vertical-align: middle !important;
    }
    td {
        height: auto;
        border-bottom: 1px solid #dee2e6;
    }
}

.myrates-container-wrapper {
    background: #eef1f5;
    padding-bottom: 25px;
    .carousel-banner {
        .carousel-indicators {
            display: none;
        }
    }
    .desktop {
        .carousel-inner {
            height: 280px !important;
            .imageSet-container-banner {
                min-height: 270px;
            }
        }
        &.carousel-banner {
            .carousel-indicators {
                bottom: 0px !important;
            }
        }
    }
    .react-bootstrap-table {
        .w-100-px {
            max-width: 100px;
        }
        .w-130-px {
            max-width: 130px;
        }
        .w-200-px {
            width: 200px;
        }
        .w-250-px {
            width: 250px;
        }
        .w-290-px {
            width: 290px;
        }
        .w-300-px {
            width: 300px;
        }
        .w-320-px {
            width: 320px;
        }
        .w-330-px {
            width: 330px;
        }
        .tbl-hd-bg {
            th {
                vertical-align: middle !important;
                font-style: normal;
                font-weight: normal;
                color: #8798a9;
                height: 40px;
                &:last-child {
                    text-align: center;
                }
            }
            &.rates {
                th {
                    &:last-child {
                        border-left: 1.5px solid #0000ff !important;
                        border-right: 1.5px solid #0000ff !important;
                        border-top: 1.5px solid #0000ff !important;
                        position: relative;
                        padding: 1rem 0 0 0;
                        &::before {
                            content: "Personalised for you";
                            background-color: #0000ff;
                            font-size: 10px;
                            color: #fff;
                            position: absolute;
                            top: 0;
                            left: 0;
                            padding: 0.1rem 0.1rem;
                            width: 100%;
                            margin: 0;
                        }
                    }
                }
                &.ar {
                    th {
                        &:last-child {
                            &::before {
                                content: "أسعار الشحن الخاصة بك";
                            }
                        }
                    }
                }
            }
        }
        .row-class {
            td {
                border-bottom: 1px solid #dee2e6;
                // text-align: center;
                &.expand-cell {
                    width: 20px;
                    i {
                        color: #0000ff;
                    }
                }
                &.date-format {
                    direction: ltr;
                    text-align: end;
                }
                &.discount-col {
                    border-left: 1.5px solid #0000ff !important;
                    border-right: 1.5px solid #0000ff !important;
                }
                &.bottom-br {
                    border-bottom: 1.5px solid #0000ff !important;
                }
            }
        }
    }
    .react-bootstrap-table-pagination {
        display: none !important;
        .react-bootstrap-table-pagination-list {
            .pagination {
                float: right !important;
            }
        }
    }
}

.dashboard-table {
    padding-right: 32px;
}

.widget {
    padding-bottom: 15px;
}

.gray-bg {
    background: #f4f6f9;
}

div.modal div.modal-dialog div.modal-content {
    div.border-right-gray {
        border-right: 1px solid #f4f6f9;
    }
    div.border-bottom-blue {
        border-bottom: 2px solid #0000ff;
    }

    div.w-fit {
        width: fit-content !important;
        margin: 0;
    }
    a.minus {
        background: #e4e8f1;
        font-size: 1rem;
        padding: 0.15rem 0.6rem;
    }
    span.text-blue {
        padding: 0.15rem 0.6rem !important;
    }
    a.plus {
        background: #0000ff;
        padding: 0.15rem 0.6rem;
        font-size: 1rem;
        .rounded-top-right-1 {
            border-top-right-radius: 1rem !important;
        }
        .rounded-bottom-right-1 {
            border-bottom-right-radius: 1rem !important;
        }
        color: #ffffff !important;
    }
}
.en {
    .myrates-container {
        .myrates-request-container {
            .table-container {
                .row-class {
                    .expand-cell {
                        padding-left: 0px;
                    }
                    .status {
                        direction: ltr;
                        .btn {
                            float: right;
                        }
                    }
                }
                .react-bootstrap-table {
                    .reset-expansion-style {
                        .cancel-request {
                            direction: rtl;
                        }
                        .desc {
                            padding-right: 8px;
                        }
                    }
                }
            }
        }
        .desktop_requests {
            .myrates-request-container {
                .create-new-request-button {
                    // position: absolute;
                    // top: -45px;
                    right: 15px;
                }
            }
        }
        .mobile_requests {
            .myrates-request-container {
                .request-button {
                    margin-top: 10px;
                }
            }
        }
    }
}
.ar {
    .myrates-container {
        .myrates-request-container {
            .table-container {
                .row-class {
                    .expand-cell {
                        padding-right: 0px;
                    }
                    .status {
                        direction: rtl;
                        .btn {
                            float: left;
                        }
                    }
                }
                .react-bootstrap-table {
                    .reset-expansion-style {
                        .cancel-request {
                            direction: ltr;
                        }
                        .desc {
                            padding-left: 8px;
                        }
                    }
                }
            }
        }
    }
}
// tablet
.brand-card {
    background: #f6f6f6;
}

.react-bootstrap-table-pagination-total {
    margin-inline: 1.5rem;
    margin-top: -17px;
}

.h-full .ReactFlagsSelect-module_selectOptions__3LNBJ {
    max-height: 150px !important;
}

.flags {
    height: 30px;
    width: 30px;
}

.shipping-icons {
    width: 100px;
}

@media only screen and (min-device-width: 766px) and (max-device-width: 1023px) {
    .myrates-container {
        width: 100%;
        padding: 0px;
    }

    .dashboard-row .col-md-9.col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .dashboard-row .row-bg-white {
        background-color: #fff;
    }
}
// mobile

@media (min-width: 320px) and (max-width: 765px) {
    .myrates-container {
        width: 100%;
        padding: 0px;
    }
}

@media only screen and (min-width: 1200px) {
    .myrates-container-wrapper {
        .banner-text-position-en {
            left: 5.5rem;
        }

        .banner-text-position-ar {
            right: 5.5rem;
        }

        .banner-img-margin-en {
            margin-right: 1.3rem;
        }

        .banner-img-margin-ar {
            margin-left: 1.3rem;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
        .myrates-container {
            margin-top: -5.4rem;
        }
    }
}

@media only screen and (min-width: 769px) {
    .myrates-container-wrapper {
        .carousel-inner {
            .xl-font {
                font-style: normal;
                font-weight: bold !important;
                font-size: 3vw !important;
            }
            .banner-text-position-ar,
            .banner-text-position-en {
                margin-top: 40px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .myrates-container-wrapper {
        .carousel-inner {
            .imageSet-container-banner {
                img {
                    &.image0 {
                        margin-top: 12rem !important;
                    }
                }
            }
            .banner-text-position-en-mobile {
                text-align: center !important;
                max-width: 100% !important;
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 990px) {
    .myrates-container-wrapper {
        .carousel-inner {
            .banner-text-position-en,
            .banner-text-position-ar {
                margin-top: 80px;
            }
        }
    }
}
@media only screen and (min-width: 990px) and (max-width: 1199px) {
    .myrates-container-wrapper {
        .carousel-inner {
            .banner-text-position-en,
            .banner-text-position-ar {
                margin-top: 70px;
            }
        }
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .myrates-container-wrapper {
        .carousel-inner {
            .banner-text-position-en,
            .banner-text-position-ar {
                margin-top: 60px;
            }
        }
    }
}
@media only screen and (max-width: 1440px) {
    .myrates-container-wrapper {
        .carousel-inner {
            .banner-img-margin-en {
                margin-right: 0rem;
            }

            .banner-img-margin-ar {
                margin-left: 0rem;
            }
        }
    }
}

@media only screen and (max-width: 1440px) {
    .myrates-container-wrapper {
        .carousel-inner {
            .imageSet-container-banner {
                img {
                    &.image0 {
                        margin-top: 12rem !important;
                    }
                }
            }
        }
    }
}
.myrates-container-wrapper {
    .carousel-inner {
        .xl-font {
            max-width: 100% !important;
        }
        .imageSet-container-banner {
            img.image1 {
                max-width: 100% !important;
            }
        }
    }
}
@media (max-width: 887px) and (min-width: 768px) {
    .myrates-container-wrapper {
        .carousel-inner {
            .banner-text-position-en {
                margin-top: 54px;
                .xl-font {
                    font-size: 22px !important;
                }
            }
        }
    }
}
@media (max-width: 768px) and (min-width: 450px) {
    .myrates-container-wrapper {
        .carousel-inner {
            height: 135px !important;
            .imageSet-container-banner {
                justify-content: end !important;
                img.image0 {
                    width: 300px !important;
                    margin-top: 15px !important;
                }
            }
            .banner-text-position-en-mobile .banner-text-position-ar-mobile {
                width: 300px !important;
                font-size: 10px !important;
                min-width: 150px !important;
                top: 10px;
                .xl-font {
                    font-size: 18px !important;
                    margin-inline-start: 1rem;
                    text-align: start !important;
                }
            }
        }
    }
}

@media (max-width: 449px) and (min-width: 375px) {
    .myrates-container-wrapper {
        .carousel-inner {
            height: 135px !important;
        }
    }
}
@media only screen and (max-width: 375px) {
    .myrates-container-wrapper {
        .carousel-inner {
            height: 135px !important;
        }
    }
}
@media only screen and (max-width: 450px) {
    .myrates-container-wrapper {
        .carousel-inner {
            .imageSet-container-banner {
                justify-content: end !important;
                img.image0 {
                    width: 200px !important;
                    margin-top: 30px !important;
                }
            }
            .banner-text-position-en-mobile .banner-text-position-ar-mobile {
                width: 100px !important;
                font-size: 10px !important;
                min-width: 78px !important;
                top: 17px;
                .xl-font {
                    font-size: 14px !important;
                    text-align: start !important;
                }
            }
        }
    }
}

.en {
    .react-bootstrap-table {
        .row-class {
            td {
                &.date-format {
                    direction: ltr;
                    text-align: start;
                }
            }
        }
    }
}
