.parcel-modal {
    .text-grey-3 {
        color: #8798a9 !important;
    }

    .auto-complete-no-padding {
        .MuiAutocomplete-inputRoot {
            padding-top: 0 !important;
        }
    }

    .styled-text-field {
        .label.Mui-focused {
            color: "green";
        }
        .MuiInput-underline:after {
            border-bottom-color: "green";
        }
        .MuiOutlinedInput-root {
            .fieldset {
                border-color: "red";
            }
            :hover .fieldset {
                border-color: "yellow";
            }
            .Mui-focused .fieldset {
                border-color: "green";
            }
        }
    }
}
