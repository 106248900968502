@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.directory-page {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    .fs-14 {
        font-size: 14px !important;
    }

    .fs-31 {
        font-size: 31px !important;
    }

    .text-grey-1 {
        color: #243548 !important;
    }

    .text-grey-2 {
        color: #3e4d5e !important;
    }

    .fw-500 {
        font-weight: 600 !important;
    }

    .fw-600 {
        font-weight: 600 !important;
    }

    .fw-700 {
        font-weight: 700 !important;
    }

    .col-1-5 {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .col-lg-1-5 {
        @include media-breakpoint-up(lg) {
            flex: 0 0 20%;
            max-width: 20%;
            padding: 0 15px;
        }
    }

    .bg-grey {
        background-color: #f4f6f9 !important;
    }

    .text-blue {
        color: #0000ff !important;
    }

    .bg-white {
        background-color: #ffffff;
    }
}
