@import "../../../constants/constants.css";

.title-buttons .ltr {
  text-align: center !important;
  margin-right: var(--navigation-button-margin-left);
}

.title-buttons .rtl {
  text-align: center !important;
  margin-left: var(--navigation-button-margin-left);
}

.title-buttons > Button {
  margin-top: 20px;
}

#warehouse {
   margin-top: 20px;
}
 
@media only screen and (max-width: 900px) {
  #warehouse {
    margin-top: 20px;
    width: 312px;
  }
}

@media only screen and (max-width: 768px) {
  .main-section-row {
    align-items: center;
    justify-content: center;
    padding: 0px 35px 0px 35px;
  }

  .title {
    font-size: var(--font-size-main-title-mobile-31);
    line-height: var(--line-height-main-title-mobile-37);
  }
}

@media only screen and (max-width: 450px) {
  .main-section-row {
    display: grid;
  }

  .ltr {
    text-align: center !important;
    align-items: center !important;
  }

  .rtl {
    text-align: center !important;
    align-items: center !important;
  }

  .title-buttons .ltr {
    margin-right: var(--navigation-button-margin-left);
  }

  .title-buttons .rtl {
    margin-left: var(--navigation-button-margin-left);
  }
}
