@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.contact-page {
    background-color: #f5f5f5;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    font-family: var(--default-font-family);

    html[dir="rtl"] & {
        text-align: right;
        font-family: var(--default-ar-font-family);
    }
}
