.hello {
    font-weight: 600;
    font-size: 24px;
    color: #000000;
}

.account-number {
    color: #243548;
}

.pin {
    color: #ff8f6b;
}

.warehouse-select {
    width: 223px;
}

.account-id {
    html[dir="rtl"] & {
        text-align: right;
    }
}
