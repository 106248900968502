@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.us-warehouse {
    .header-wrapper {
        // preserve aspect ratio
        position: relative;
        margin-bottom: 64px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0px;
        }

        &:before {
            content: "";
            display: block;
            background-image: url("./header-ltr-mobile.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            padding-top: 139.5%; // aspect ratio

            @include media-breakpoint-up(sm) {
                background-size: cover;
                background-position: center;
                background-image: url("./header-ltr.svg");
                padding-top: 36.6%; // aspect ratio
                min-height: 475px;
            }
        }

        &.right-to-left {
            &:before {
                background-image: url("./header-rtl-mobile.svg");

                @include media-breakpoint-up(sm) {
                    background-image: url("./header-rtl.svg");
                }
            }
        }

        > .content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            &.rtl {
                text-align: right !important;
            }

            @include media-breakpoint-up(sm) {
                padding-left: #{"min(9.8vw, 188px)"} !important;

                &.rtl {
                    padding-left: 0 !important;
                    padding-right: #{"min(9.8vw, 188px)"} !important;
                }
            }
        }
    }

    .shop-latest {
        font-size: 42px;

        @media (max-width: 400px) {
            font-size: 26px;
        }

        @include media-breakpoint-up(sm) {
            font-size: 62px;
        }
    }

    .get-delivered {
        font-size: 24px;

        @media (max-width: 400px) {
            font-size: 20px;
        }

        @include media-breakpoint-up(sm) {
            font-size: 42px;
        }
    }
}
