.green-bg {
    background: #00EB8C;
}

.banner-en {
    background: url("./banner-en.png") no-repeat center;
    background-size: contain;
    background-position: inherit;
    height: 37vw;
    position: relative;
    text-align: start;
    span.need-help {
        position: absolute;
        left: 10%;
        top: 50px;
        font-size: 3vw;
        color: #0000FF;
    }
    
    span.service-text {
        position: absolute;
        left: 10%;
        top: 220px;
        font-size: 2vw;
        color: #FFFFFF;
    }
    
    a.signup-btn {
        position: absolute;
        left: 10%;
        top: 300px;
    }
    
    span.color-primary {
        color: #0000FF;
        font-weight: 500;
        font-size: 2.5rem;
    }    
}

.banner-ar {
    background: url("./banner-ar.png") no-repeat center;
    background-size: contain;
    background-position: inherit;
    height: 37vw;
    position: relative;
    text-align: start;
    span.need-help {
        position: absolute;
        right: 10%;
        top: 50px;
        font-size: 3vw;
        color: #0000FF;
    }
    
    span.service-text {
        position: absolute;
        right: 10%;
        top: 220px;
        font-size: 2vw;
        color: #FFFFFF;
    }
    
    a.signup-btn {
        position: absolute;
        right: 10%;
        top: 300px;
    }  
}

span.color-primary {
    color: #0000FF;
    font-weight: 500;
    font-size: 2.5rem;
}

div.step-en-end {
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    .left-arrow {
        position: absolute;
        top: 90px;
        left: 10%;
        width: 12vw
    }
    .step {
        position: absolute;
        top:95px;
        left: 12.5%;
        font-size: 1.8vw;
    }
    .step-img {
        width: 65vw
    }
    .step-desc {
        position: absolute;
        top: 160px;
        text-align: start;
        left: 10%;
        font-weight: 400;
        font-size: 1.5vw;
        width: 26vw;
    }
    margin: 1rem 0;
}

div.step-en-start {
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    .left-arrow {
        position: absolute;
        top: 100px;
        right: 16%;
        width: 12vw
    }
    .step {
        position: absolute;
        top: 105px;
        right: 18.5%;
        font-size: 1.8vw;
    }
    .step-img {
        width: 65vw
    }
    .step-desc {
        position: absolute;
        top: 170px;
        text-align: start;
        right: 6%;
        font-weight: 400;
        font-size: 1.5vw;
        width: 20vw;
    }
    margin: 1rem 0;
}

div.step-ar-end {
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    
    .right-arrow {
        position: absolute;
        top: 90px;
        right: 10%;
        width: 12vw
    }
    .step {
        position: absolute;
        top: 95px;
        right: 11.5%;
        font-size: 1.8vw;
    }
    .step-desc {
        position: absolute;
        top: 160px;
        text-align: start;
        right: 10%;
        font-weight: 400;
        font-size: 1.5vw;
        width: 20vw;
    }
    .step-img {
        width: 65vw
    }
    margin: 1rem 0;
}

div.step-ar-start {
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    
    .right-arrow {
        position: absolute;
        top: 70px;
        left: 25%;
        width: 12vw
    }
    .step {
        position: absolute;
        top: 75px;
        left: 26.5%;
        font-size: 1.8vw;
    }
    .step-desc {
        position: absolute;
        top: 140px;
        text-align: start;
        left: 15%;
        font-weight: 400;
        font-size: 1.5vw;
        width: 20vw;
    }
    .step-img {
        width: 65vw
    }
    margin: 1rem 0;
}

.bottom-links {
    font-size: 1.5rem;
}

//max-width: 768px
@media only screen and (max-width: 768px) {
    .banner-en {
        background: url("./banner-en-mobile.png") no-repeat center;
        background-size: cover;
        height: 120vh;
        position: static;
        span.need-help {
            position: static;
            max-width: 70vw;
            font-size: 10vw;
            padding-top: 2rem;
            margin-left: 3rem;
            color: #0000FF;
        }
        
        span.service-text {
            margin: 2rem 0 2rem 3rem;
            position: static;
            display: block;
            font-size: 6vw;
            color: #FFFFFF;
        }
        a.signup-btn {
            position: static;
            margin-left: 3rem;
        }
    }
    .banner-ar {
        background: url("./banner-ar-mobile.png") no-repeat center;
        background-size: cover;
        height: 120vh;
        position: static;
        span.need-help {
            position: static;
            max-width: 80vw;
            padding-top: 2rem;
            margin-right: 3rem;
            font-size: 5vh;
            margin-bottom: 1rem;
        }
        
        span.service-text {
            position: static;
            margin: 2rem 3rem 2rem 0;
            font-size: 3.5vh;
            color: #FFFFFF;
            strong {
                display: inline-block;
                padding: 0 3rem 2rem 0;
            }
        }

        a.signup-btn {
            position: static;
            margin-right: 3rem;
        }
    }

    span.color-primary {
        width: 50%;
        font-size: 2rem;
        margin: 3rem 0;
    }

    div.step-en-start {
        display: block;
        .left-arrow {
            top: 0;
            right: 70px;
            width: 20%;
        }
        .step {
            top: 2px;
            font-size: 3.2vw;
            right: 85px;
        }
        .step-desc {
            top: 30px;
            right: 67px;
            width: 42vw;
            font-size: 2.5vw;
        }
        .step-img {
            margin-top: 10vh;
            margin-bottom: 2rem;
            width: 100%
        }
    }
    div.step-en-end {
        display: block;
        .left-arrow {
            top: 0;
            width: 20%;
        }
        .step {
            top: 2px;
            font-size: 3.2vw;
        }
        .step-desc {
            top: 5vh;
            width: 40vw;
            font-size: 2.5vw;
        }
        .step-img {
            margin-top: 10vh;
            margin-bottom: 2rem;
            width: 100%
        }
    }
    div.step-ar-start {
        display: block;
        .right-arrow {
            top: 0;
            left: 70px;
            width: 20%;
        }
        .step {
            top: 2px;
            left: 80px;
            font-size: 3.2vw;
        }
        .step-desc {
            top: 5vh;
            font-size: 2.5vw;
            right: 9vh;
            width: 100vw;
        }
        .step-img {
            margin-top: 10vh;
            margin-bottom: 2rem;
            width: 100%
        }
    }
    div.step-ar-end {
        display: block;
        .right-arrow {
            top: 0;
            left: 50%;
            width: 20%;
        }
        .step {
            top: 2px;
            font-size: 3.2vw;
        }
        .step-desc {
            top: 5vh;
            font-size: 2.5vw;
            left: 50%;
            width: 40vw;
        }
        .step-img {
            margin-top: 10vh;
            margin-bottom: 2rem;
            width: 100%
        }
    }

}