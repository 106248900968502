@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.directory-page {
    .card-container {
        margin-bottom: 35px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 43px;
        }

        > .card-image {
            height: 170px;
            background-position: bottom center;
            background-size: contain;
            background-repeat: no-repeat;
            margin-bottom: 26px;
            @include media-breakpoint-up(sm) {
                margin-bottom: 35px;
            }
        }

        > .card-title {
            font-weight: 500;
            font-size: 20px;
            color: #243548;
            margin-bottom: 11px;
            @include media-breakpoint-up(sm) {
                font-size: 22px;
                margin-bottom: 12px;
            }
            &.ar {
                font-weight: 600;
            }
        }

        > .card-description {
            font-size: 15px;
            color: #3e4d5e;
            @include media-breakpoint-up(sm) {
                font-size: 16px;
            }
        }
    }
}
