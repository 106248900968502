.modal {
    max-width: 978px !important;
    color: #243548;
    font-size: 16px;
    font-weight: 400;
    user-select: none;
    html[dir="rtl"] & {
        text-align: right;
    }
}

.modal-title {
    font-size: 26px !important;
    font-weight: 500px !important;
}

.modal-body {
    padding: 40px;
}

.cards-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (min-width: 1128px) {
        flex-direction: row;
        align-items: unset;
        justify-content: space-between;
    }
}

.card {
    background-color: #f4f6f9;
    padding: 23px;
    border-radius: 6px;
    width: 47.427%;
    min-width: 360px;
}

.country-title {
    font-size: 22px;
    font-weight: 500;
}

.flag {
    display: inline-block;
    width: 26px;
    margin-right: 12px;
    html[dir="rtl"] & {
        margin-right: unset;
        margin-left: 12px;
    }
}

.form-field-value {
    font-weight: 500;
    border: 1px solid #e4e8f1;
    background-color: #ffffff;
    padding: 8px 16px;
    text-align: left;
    &:hover {
        cursor: pointer;
    }
}

.button-icon {
    transition: all 100ms;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }

    &:active {
        opacity: 0.5;
    }
}

.text-orange {
    color: #ff8f6b;
}

.double-field {
    min-height: 96px;
}

.copy-all {
    font-size: 14px;
    color: #8798a9;
    opacity: 0.67;
}

.copy-all-icon {
    margin-right: 5px;
    width: 12px;
    height: 12px;
    html[dir="rtl"] & {
        margin-right: unset;
        margin-left: 5px;
    }
}